import React from "react";
import { Link } from "gatsby";
import Layout from "../layouts/index";
import { FaBezierCurve } from "react-icons/fa";

const style = {
  box: {
    marginBottom: 40,
  },
  BGccc: {
    backgroundColor: "#ccc",
  },
  BgYellow: {
    backgroundColor: "yellow",
  },
  section: {
    paddingTop: 100,
  },
};

const FeeContent = () => (
  <div className="container sub-content">
    <h1>費用（料金）について</h1>
    <div style={style.box}>
      <ul>
        <li>
          <Link to="#kensin">定期健康診断</Link>
        </li>
        <li>
          <Link to="#dock">人間ドック</Link>
        </li>
        <li>
          <Link to="#other">その他の検診</Link>
        </li>
        <li>
          <Link to="#kouishou">新型コロナ後遺症検診</Link>
        </li>
      </ul>
    </div>
    <div id="kensin" style={style.section}>
      <div style={style.box}>
        <div>
          <h2>定期健康診断</h2>
        </div>
        <div>
          <p>
            一般定期健診では4種類、生活習慣病健診では2種類の料金設定をご用意しております。
          </p>
          <p>
            (1)~(5)の一般定期検診は簡易版となります。また、結果の受取をお急ぎの方のためにオプションをご用意しましたので
            <Link to="/blogs/2012-7-16_2" target="_blank">
              <b>こちら</b>
            </Link>
            を御覧ください。
          </p>
          <p>
            <Link to="../health-checking/for-companies">
              <b>健康診断をお考えの企業様はこちらをご一読ください。</b>
            </Link>
          </p>
        </div>
        <div>
          <table className="table table-bordered table-responsive table-hover">
            <thead>
              <tr>
                <th className="text-center" rowSpan="2">
                  検査項目（↓）／コース種類（→）
                </th>
                <th className="text-center" colSpan="4">
                  一般定期健診
                </th>
                <th className="text-center" colSpan="2">
                  生活習慣病健診
                </th>
              </tr>
              <tr>
                <th className="nowrap text-center">
                  <div>（1）～（5）</div>
                  <div>￥5,000</div>
                </th>
                <th className="nowrap text-center">
                  <div>（1）～（6）</div>
                  <div>￥5,500（法定）</div>
                </th>
                <th className="nowrap text-center">
                  <div>（1）～（7）</div>
                  <div>￥9,000</div>
                </th>
                <th className="nowrap text-center">
                  <div>（1）～（8）</div>
                  <div>￥11,000（法定）</div>
                </th>
                <th className="nowrap text-center">
                  <div>（1）～（11）</div>
                  <div>￥20,000</div>
                </th>
                <th className="nowrap text-center">
                  <div>（1）～（12）</div>
                  <div>￥33,000</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>（1）身体計測（身長・体重・BMI・視力・腹囲）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（2）血圧測定</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（3）尿検査（糖・蛋白・ウロビリノーゲン・潜血反応）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（4）胸部レントゲン〈直接撮影〉</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（5）診察（内科診察）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（6）聴力検査（オージオメーター ）</td>
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  <ul className="list-unstyled">
                    <li>
                      （7）血液検査（WBC・RBC・Hb・Ht・PLT・AST(GOT)・ALT(GPT)・γ-GTP・LDLコレステロール・HDLコレステロール・トリグリセライド・空腹時血糖）
                    </li>
                    <li>※雇用時および35歳、40歳以上の方は必須</li>
                  </ul>
                </td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（8）心電図検査※雇用時および35歳、40歳以上の方は必須</td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（9）血液検査（尿酸・尿素窒素・クレアチニン・HbA1c）</td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（10）便検査（便潜血）　２回</td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（11）腹部超音波検査（胆嚢・肝臓・膵臓・腎臓）</td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （12）鼻から診る苦しくない胃カメラ（食道・胃・十二指腸）感染症検査（HBs抗原・HCV抗体・梅毒（TPHA））
                </td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="onTheDay" style={style.box}>
        <div style={style.box}>
          <div>
            <h2>オプション</h2>
            <p>
              予約枠に空きがあれば当日にご予約いただくことも可能です。追加料金は下記をご参照ください。
            </p>
          </div>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="text-center">血液検査あり</td>
                <td className="text-center">＋3,000円</td>
              </tr>
              <tr>
                <td className="text-center align-middle">血液検査なし</td>
                <td className="text-center">＋2,000円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="op" style={style.box}>
        <div style={style.box}>
          <div>
            <h2>結果お急ぎオプション</h2>
            <p>はやめに結果が必要な方はこちらのオプションをお選びください。</p>
          </div>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th className="text-center"></th>
                <th className="text-center">結果お渡しまでかかる時間</th>
                <th className="text-center">検査項目の増減</th>
                <th className="text-center">料金</th>
              </tr>
              <tr>
                <td className="text-center">通常</td>
                <td className="text-center">10日～2週間程度</td>
                <td className="text-center">◎</td>
                <td className="text-center">追加料金なし</td>
              </tr>
              <tr>
                <td className="text-center align-middle">急ぎ</td>
                <td className="text-center">
                  指定日までに
                  <br />
                  <span style={{ fontSize: 12 }}>
                    （項目によっては健診日の翌日よりお渡し可能）
                  </span>
                </td>
                <td className="text-center align-middle">
                  〇<br />
                  <span style={{ fontSize: 10 }}>増やせない項目あり</span>
                </td>
                <td className="text-center align-middle">+500円</td>
              </tr>
              <tr>
                <td className="text-center align-middle">当日</td>
                <td className="text-center align-middle">受診当日</td>
                <td className="text-center">
                  △<br />
                  <span style={{ fontSize: 10 }}>ほぼ増やせない</span>
                </td>
                <td className="text-center align-middle">+4,000円</td>
              </tr>
              {/* <tr>
              <td className="text-center align-middle">特急</td>
              <td className="text-center align-middle">申込当日(<Link to="https://questionaire.hirahata.net/">お申し込みはこちらから</Link>)</td>
              <td className="text-center">
                ×<br />
                <span style={{ fontSize: 10 }}>項目固定</span>
              </td>
              <td className="text-center align-middle">17,000円</td>
            </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="dock" style={style.section}>
      <div style={style.box}>
        <div style={style.box}>
          <div>
            <h2>人間ドック （半日コース）</h2>
          </div>

          <div>
            <p>
              人間ドックを３名様以上でご利用の場合、団体割引が適応されます。
              <br />
              （※ネットの予約サービスをご利用いただいた場合を除く）
            </p>
            <p>
              <span>
                <strong>
                  各コースとも、時短人間ドックをご利用いただけます。
                </strong>
              </span>
            </p>
            <p>
              通常、人間ドックの
              <span>
                <strong>所要時間は約３時間</strong>
              </span>
              ですが、お急ぎの方のために、胃カメラ以外の結果説明を省略し、
              <span>
                <strong>１時間半～２時間前後</strong>
              </span>
              で終了する時短人間ドックもございます。結果説明を行わない検査については、後日紙面でご報告いたします（
              <span>料金は変わりません</span>）。
              <br />
              また、報告内容にご不明な点がある場合は、外来にお越しいただくか、メールにてお問い合わせください。ご不明点を医師からご説明いたします。
              <br />
              <span>
                <strong>
                  時短人間ドックをご希望の方はお申し込みの際にお伝えください。
                </strong>
              </span>
            </p>
          </div>
          <div>
            <p>＞院長ブログより</p>
            <Link to="/blogs/2009-6-23_2">
              健康診断日誌　2009.06.24　団体割引で賢く受診
            </Link>
          </div>
        </div>

        <div>
          <p style={style.BgYellow}>
            <strong>
              ※ 2020年12月現在
              <br />
              新型コロナウイルス感染防止のため、肺活量検査は現在休止させていただいておりますのでご了承ください。
            </strong>
          </p>
        </div>

        <div>
          <table className="table table-bordered table-responsive table-hover">
            <thead>
              <tr>
                <th>検査項目（↓）／コース種類（→）</th>
                <th className="nowrap text-center">
                  <div>〔標準〕</div>
                  <div>
                    （1）～（12）<strong>￥43,000</strong>
                  </div>
                  <div>（団）￥40,000</div>
                </th>
                <th className="nowrap text-center">
                  <div>〔精密１〕</div>
                  <div>
                    （1）～（13）<strong>￥50,000</strong>
                  </div>
                  <div>（団）￥46,500</div>
                </th>
                <th className="nowrap text-center">
                  <div>
                    〔精密２〕<strong>※女性のみ</strong>
                  </div>
                  <div>
                    （1）～（14）<strong>￥55,000</strong>
                  </div>
                  <div>（団）￥50,000</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  （1）身体計測（身長・体重・BMI・体脂肪率・腹囲・視力・聴力）
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（2）血圧測定　2回</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （3）尿検査（糖・蛋白・ウロビリノーゲン・潜血反応・Ph・ビリルビン・ケトン体・沈渣）
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（4）便検査（免疫学的潜血反応）2回</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （5）血液検査（総蛋白・蛋白分画・総ビリルビン・AST(GOT)・ALT(GPT)・γ-GTP・ALP・LDH・LDLコレステロール・トリグリセライド・
                  HDLコレステロール・血清アミラーゼ・リパーゼ・尿酸・尿素窒素・クレアチニン・血清鉄・空腹時血糖・HbA1c・WBC・RBC・Hb・Ht・
                  PLT・CRP・RA・HBs抗原・HCV抗体・梅毒（TPHA））
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr style={style.BGccc}>
                <td>
                  （6）肺機能検査（肺活量・1秒率・1秒量・％肺活量）
                  <br />
                  <strong>【現在休止中】</strong>
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（7）心電図検査</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（8）超音波検査（胆嚢・肝臓・膵臓・腎臓）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（9）胸部レントゲン〈直接撮影〉</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （10）鼻から診る苦しくない胃カメラ（食道・胃・十二指腸）
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（11）眼科検査（色神・眼底）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（12）診察（内科診察・生活指導）</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  <ul className="list-unstyled">
                    <li>（13）腫瘍マーカー（血液によるがん検査）</li>
                    <li className="add-indent-list">
                      共通→胃・肺・膵・大腸がん
                    </li>
                    <li className="add-indent-list">
                      （AFP精密・CEA・CA19-9）
                    </li>
                    <li className="add-indent-list">
                      男性のみ→前立腺がん（PSA）
                    </li>
                    <li className="add-indent-list">
                      女性のみ→卵巣がん （CA125）
                    </li>
                  </ul>
                </td>
                <td />
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（14）乳がん検査（女性技師による超音波検査）</td>
                <td className="text-center" />
                <td className="text-center" />
                <td className="text-center">〇</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={style.box}>
        <div>
          <h2>オプション検査</h2>
        </div>
        <div>
          <table className="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>痛みのない～少ない大腸カメラ</td>
                <td>￥22,000</td>
              </tr>
              <tr>
                <td>鼻から診る苦しくない胃カメラ（食道・胃・十二指腸）</td>
                <td>￥16,000</td>
              </tr>
              <tr>
                <td>腹部超音波</td>
                <td>￥7,000</td>
              </tr>
              <tr>
                <td>ヘリコバクター･ピロリ（ピロリ菌）検査</td>
                <td>￥2,500</td>
              </tr>
              <tr>
                <td>ペプシノーゲン</td>
                <td>￥2,500</td>
              </tr>
              <tr>
                <td>ABC健診</td>
                <td>￥5,000</td>
              </tr>
              <tr>
                <td>
                  女性技師による乳がん検査（乳腺エコー検査）
                  <br />
                  こちらのみでの受診も可能です。
                </td>
                <td>￥5,000</td>
              </tr>
              <tr>
                <td>
                  心臓･血管チェック
                  <br />
                  （心電図検査･心臓エコー検査･頸動脈エコー検査･脈波（血管年齢）検査）
                </td>
                <td>￥21,000</td>
              </tr>
              <tr>
                <td>便潜血（２回）</td>
                <td>￥2,500</td>
              </tr>
              <tr>
                <td>胸部レントゲン</td>
                <td>￥2,000</td>
              </tr>
              <tr>
                <td>視力</td>
                <td>￥500</td>
              </tr>
              <tr>
                <td>
                  便細菌（病原性大腸菌O-157･サルモネラ･チフス･赤痢菌･パラチフスA菌）
                </td>
                <td>￥4,000</td>
              </tr>
              <tr>
                <td>聴力</td>
                <td>￥500</td>
              </tr>
              <tr>
                <td>色覚（色神）</td>
                <td>￥500</td>
              </tr>
              <tr>
                <td>脈波検査（動脈硬化度測定）</td>
                <td>￥4,000</td>
              </tr>
              <tr>
                <td>心電図検査</td>
                <td>￥2,000</td>
              </tr>
              <tr>
                <td>ホルター心電図</td>
                <td>￥18,000</td>
              </tr>
              <tr>
                <td rowSpan="3">腫瘍マーカー（血液によるがん検査）</td>
                <td>
                  胃･肺･膵･大腸がん
                  <br /> （AFP精密・CEA・CA19-9）　￥6,000
                </td>
              </tr>
              <tr>
                <td>前立腺がん（PSA）　￥3,000</td>
              </tr>
              <tr>
                <td>卵巣がん（CA125）　￥3,500</td>
              </tr>
              <tr>
                <td rowSpan="2">
                  アミノインデックス（血液によるがん検査）
                  <br />
                  ※対象年齢以外の方が受診された場合でも検査結果は報告されますが、AICS値やランク判定結果は評価対象外となります。
                  <br />
                  一部のがん種についてのみが対象年齢外になる場合、そのがん種は評価対象外となりますので、ご理解いただいた上でご受診ください。
                </td>
                <td>
                  男性：胃･肺･大腸･膵臓（対象年齢25～90歳）
                  <br />
                  　　　前立腺がん（対象年齢40～90歳）
                  <br />
                  　　　￥26,000
                </td>
              </tr>
              <tr>
                <td>
                  女性：胃･肺･大腸･膵臓･乳（対象年齢25～90歳）
                  <br />
                  　　　子宮･卵巣がん（対象年齢20～80歳）
                  <br />
                  　　　￥26,000
                </td>
              </tr>
              <tr>
                <td>リウマチ因子（RF）</td>
                <td>￥500</td>
              </tr>
              <tr>
                <td>血液型</td>
                <td>￥1,000</td>
              </tr>
              <tr>
                <td>男性更年期症候群スクリーニング（遊離テストステロン）</td>
                <td>￥2,500</td>
              </tr>
              <tr>
                <td rowSpan="3">
                  薬物中毒検査(尿)
                  <br />
                  <a href="/../blogs/2018-5-9/">
                    （薬物検査を健診に組み込むメリット）
                  </a>
                </td>
              </tr>
              <tr>
                <td>覚せい剤･大麻･コカイン系麻薬・モルヒネ系麻薬　￥8,000</td>
              </tr>
              <tr></tr>
              <tr>
                <td>リパーゼ</td>
                <td>￥500</td>
              </tr>
              <tr>
                <td>トリプシン</td>
                <td>￥2,000</td>
              </tr>
              <tr>
                <td>エラスターゼ1</td>
                <td>￥2.000</td>
              </tr>
              <tr>
                <td>遅延型フードアレルギー</td>
                <td>￥55,000</td>
              </tr>
              <tr>
                <td>毛髪ミネラル検査</td>
                <td>￥15,000</td>
              </tr>
              <tr>
                <td>骨粗鬆症の検査</td>
                <td>￥3,500</td>
              </tr>
              <tr>
                <td>
                  英文診断書作成（急ぎ指定不可。健診料金は別途かかります）
                </td>
                <td>￥4,500～￥6,000</td>
              </tr>
              <tr>
                <td>画像CD-R</td>
                <td>￥1,500</td>
              </tr>
            </tbody>
          </table>
          <div>
            <Link to="/blogs/2013-3-22">
              健康診断日誌　2013.03.22　結果票、診断書が複数枚必要な場合の料金について
            </Link>
            <p style={{ marginTop: 20 }}>
              骨粗鬆症の検査について <br />
              当院ではMD(microdensitometry)法を採用しております。
              <br />
              第二中手骨を用いるRA(radiographic
              absorptiometry：X線撮影画像の濃淡や皮質骨の幅から骨密度を評価する方法)の一つになり、骨粗鬆症の診断に用いられます。撮影は椅子に座り、レントゲンフィルムに手を広げておいた状態で行います。(約5分程度で終了)
              <br />
              骨粗鬆症からのリスクとして、女性(閉経後)、高齢、家族歴、喫煙、飲酒等があげられます。
              <br />
              食事や運動で予防も可能なので、ぜひ今のご自身の状態を把握して適切な指導を受けてみませんか。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="other" style={style.section}>
      <div style={style.box}>
        <h2>その他の検診</h2>
      </div>
      <div>
        <table className="table table-bordered table-hover">
          <tbody>
            <tr>
              <td rowSpan="2">美容師診断書</td>
              <td>胸部レントゲン検査含む、当日お渡し　￥5,000</td>
            </tr>
            <tr>
              <td>過去3ヶ月以内の健康診断結果持参　￥3,000</td>
            </tr>

            <tr>
              <td>警備診断書</td>
              <td>当日お渡し　￥3,000</td>
            </tr>

            <tr>
              <td rowSpan="2">スペイン診断書</td>
              <td>胸部レントゲン検査含む、当日お渡し　￥6,000</td>
            </tr>
            <tr>
              <td>過去3ヶ月以内以内の健康診断結果持参　￥4,500</td>
            </tr>

            <tr>
              <td>《適正健診》①視力②聴力③色覚④診察⑤診断書</td>
              <td>￥4,000</td>
            </tr>
            <tr>
              <td>
                <a href="https://www.sutoresu.jp/">
                  ストレスチェックサービス(詳しくはこちらをご覧ください)
                </a>
              </td>
              <td>￥500(実施一人当たり)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="kouishou" style={style.section}>
      <div style={style.box}>
        <div>
          <h2>新型コロナ後遺症検診</h2>
          <p>
            新型コロナ後遺症によって引き起こされがちな異常（コレステロール上昇、糖尿病、心筋炎・心筋症）や、新型コロナ後遺症との関連が疑われる疾患（膵疾患）、よく似た症状を呈する疾患を調べる検査を、最小限の滞在時間で行います。
            <br />
            新型コロナワクチン長期副反応が心配な方にもおすすめです。
            <br />
            N蛋白に対するIgG抗体（ワクチン接種の有無に関係なく、陽性が出れば新型コロナ感染歴が疑われます）、S蛋白に対するIgG抗体（ワクチン接種や新型コロナ感染で上昇する抗体です）を同時に測定するコースもご用意いたしました。
          </p>
        </div>
        <div>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>検査項目</th>
                <th className="nowrap text-center">
                  通常
                  <br />
                  <b>¥38,500</b>
                </th>
                <th className="nowrap text-center">
                  抗体検査あり
                  <br />
                  <b>¥48,500</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>（1）胸部X線</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>（2）心電図</td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （3）血液検査
                  <br />
                  抗核抗体
                  <br />
                  白血球数、赤血球数、ヘモグロビン、ヘマトクリット、血小板数、ＭＣＶ、ＭＣＨ、ＭＣＨＣ
                  <br />
                  総ビリルビン、AST（GOT)、ALT（GPT)、LDH(IFCC法)、総蛋白質、アルブミン、γ－GTP、HDL
                  ｺﾚｽﾃﾛｰﾙ、LDL ｺﾚｽﾃﾛｰﾙ、中性脂肪
                  <br />
                  血糖、HbA1c(NGSP)
                  <br />
                  アミラーゼ、リパーゼ、トリプシン
                  <br />
                  クレアチニン、BNP/CLIA、コルチゾール
                  <br />
                  TSH、FT3、FT4
                  <br />
                  Na、K、Cl、Ca、Mg、Cu、Zn、フェリチン
                  <br />
                  CRP
                  <br />
                  TnT定量、（Dダイマー：資材不足のため、Dダイマーは現在施行できません）
                </td>
                <td className="text-center">〇</td>
                <td className="text-center">〇</td>
              </tr>
              <tr>
                <td>
                  （4）抗体検査
                  <br />
                  <ul>
                    <li>
                      IgG(N) <br />
                      N蛋白に対するIgG抗体（ワクチン接種の有無に関係なく、陽性が出れば新型コロナ感染歴が疑われます）
                    </li>
                    <li>
                      IgG(S) <br />
                      S蛋白に対するIgG抗体（ワクチン接種や新型コロナ感染で上昇する抗体です）
                    </li>
                  </ul>
                </td>
                <td className="text-center"></td>
                <td className="text-center">〇</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={style.box}>
        <div>
          <h2>新型コロナ後遺症の脱毛症状にお悩みの方向けコース</h2>
          <p>
            髪の毛が抜けてしまう原因として亜鉛欠乏、鉄欠乏、甲状腺機能低下があります。
            <br />
            もしこれらがある場合は、治療をすることで症状の改善できることが少なくありません。
            <br />
            特にコロナ後遺症、ワクチン長期副反応の方は、亜鉛が足りていないことが非常に多いので注意が必要。
            <br />
            後遺症等でない方も、ご利用可能。脱毛の意外な原因が分かることがあります。
            <br />
            検査は採血をするだけ。異常があれば、治療のアドバイスつきの結果をお返しします。
          </p>
        </div>
        <div>
          <table className="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>検査項目</td>
                <td>費用</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>甲状腺機能（TSH、FT3、FT4）</li>
                    <li>血算（WBC・RBC・Hb・Ht・ PLT）</li>
                    <li>鉄（Fe）、フェリチン</li>
                    <li>亜鉛（Zn）、銅（Cu）</li>
                  </ul>
                </td>
                <td>￥14000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={style.box}>
        <div>
          <h2>新型コロナ抗体検査</h2>
        </div>
        <div>
          <table className="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>検査項目</td>
                <td>費用</td>
              </tr>
              <tr>
                <td>IgG(N)</td>
                <td>￥4,000</td>
              </tr>
              <tr>
                <td>IgG(S)</td>
                <td>￥6,000</td>
              </tr>
              <tr>
                <td>IgM</td>
                <td>￥5,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

const Fee = () => (
  <Layout>
    <FeeContent />
  </Layout>
);

export default Fee;
